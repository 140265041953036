import { register } from "swiper/swiper-element-bundle.mjs";

import * as nav from "./modules/navigation.js";
import animateOnScroll from "./modules/observer.js";

// Register Swiper custom elements
register();

// Stop transitions before page has loaded
window.addEventListener(
  "load",
  (event) => {
    document.body.classList.remove("preload");
  },
  false,
);

// UI
$(".accordion-toggle").click(function () {
  $(this).next().slideToggle();
  $(this).find("svg").toggleClass("hidden");
  $(this).toggleClass("text-highlight");
});

// Home transition
const mask = document.querySelector(".mask");

if (mask) {
  window.addEventListener("scroll", () => {
    scaleOnScroll();
  });

  window.addEventListener("resize", () => {
    scaleOnScroll();
  });

  scaleOnScroll();
}

function scaleOnScroll() {
  const vh = window.innerHeight + 70; // Small height offset to account for padding
  const scrolled = document.documentElement.scrollTop;

  if (scrolled > 20) {
    $(".scroll-hint").fadeOut();
  }

  if (scrolled > vh) {
    mask.classList.add("absolute");
  } else {
    mask.classList.remove("absolute");
  }

  const max = 720; // Set the max to a size that covers the screen
  const min = 40; // Set the min to the desired final shape size

  let pct = max - Math.round((scrolled / vh) * max);

  if (pct <= min) {
    pct = min;
  }

  mask.style.maskSize = "auto " + pct + "%";
}
