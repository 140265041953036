const menu = document.querySelector("#main-navigation");
const hamburger = document.querySelector("#hamburger");
const menuIcon = document.querySelector(".menuIcon");
const closeIcon = document.querySelector(".closeIcon");
const logo = document.querySelector(".site-logo");

// Toggle nav
hamburger.addEventListener("click", function () {
  menu.classList.contains("hidden") ? openNav() : closeNav();
});

// Hide nav on window resise
window.addEventListener("resize", (event) => {
  if (window.innerWidth > 950) {
    closeNav();
  }
});

$(".toggle-subnav").click(function () {
  if ($(window).width() < 950) {
    $(this).next(".subnav").removeClass("hidden");
  } else {
    const elem = $(this);
    const siblings = $(".toggle-subnav").not(elem);

    closeSubnav(siblings);
    $(this).next(".subnav").hasClass("nav:scale-y-100")
      ? closeSubnav(elem)
      : openSubnav(elem);
  }
});

const openSubnav = (button) => {
  button
    .next(".subnav")
    .addClass("nav:scale-y-100", "nav:pointer-events-auto")
    .removeClass("nav:pointer-events-none");
  button.addClass("nav:text-creek");
  button.find("svg").addClass("nav:rotate-180");
  console.log("open");
};

const closeSubnav = (button) => {
  button
    .next(".subnav")
    .removeClass("nav:scale-y-100", "nav:pointer-events-auto")
    .addClass("nav:pointer-events-none");
  button.removeClass("nav:text-creek");
  button.find("svg").removeClass("nav:rotate-180");
  console.log("close");
};

$(".close-subnav").click(function () {
  $(".subnav").addClass("hidden");
});

// Functions
const openNav = () => {
  menu.classList.remove("hidden");
  menuIcon.classList.add("hidden");
  closeIcon.classList.remove("hidden");
  logo.classList.add("fill-white");
};

const closeNav = () => {
  menu.classList.add("hidden");
  menuIcon.classList.remove("hidden");
  closeIcon.classList.add("hidden");
  logo.classList.remove("fill-white");
  $(".subnav").addClass("hidden");
};

export { openNav, closeNav };
